<template>
  <v-dialog
    v-model="isVisible"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ isEditMode? "Deploy ": "Add New " }}Phone Number</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="field.name"
                label="Name *"
                required
                :error="!(field.name && field.name.length) && isFormDirty"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="field.number"
                v-mask="'+1 (###) ###-####'"
                placeholder="+1 (___) ___-____"
                required
                disabled="true"
                label="Cell Number *"
                :error="!(field.number && field.number.length === 14 ) && isFormDirty"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="field.signature"
                label="Signature"
              />
            </v-col>
          </v-row>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="submit"
        >
          {{ isEditMode? "Deploy": "Add" }}
        </v-btn>
      </v-card-actions>
      <v-snackbar
        v-model="snackbar"
      >
        {{ notification }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import { VueMaskDirective } from 'v-mask'
  import Vue from 'vue'
  Vue.directive('mask', VueMaskDirective)

  export default {
    name: 'PhoneNumberAddEditDialog',
    props: {
      isVisible: {
        type: Boolean,
        default: false,
      },
      isEditMode: {
        type: Boolean,
        default: false,
      },
      selectedPhoneNumber: {
        type: Object,
        default: null,
      },
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      username: null,
      snackbar: false,
      field: {
        name: '',
        number: '',
        signature: '',
      },
      isFormDirty: false,

    }),

    watch: {
      isVisible (val) {
        this.isFormDirty = false
        if (val) {
          // Dialog opened
          if (this.isEditMode) {
            // Opening in edit mode
            // Assign exisiting entry values
            this.field.number = this.prepareMaskedNumber(this.selectedPhoneNumber.PhoneNumber)
            this.field.name = this.selectedPhoneNumber.Name
            if (this.selectedPhoneNumber.Signiture && this.selectedPhoneNumber.Signiture.length) {
              this.field.signature = this.selectedPhoneNumber.Signiture
            }
          } else {
            // Opening in add mode
            // Reset all fields
            this.field = Object.assign({
              name: '',
              number: '',
              signature: '',
            })
          }
        } else {
        }
      },
    },

    created () {
      this.username = localStorage.getItem('username')
    },

    methods: {
      async initialize () {
      },

      prepareMaskedNumber (n) {
        let maskedNumber = ''
        maskedNumber = n.replace('+1', '')
        maskedNumber = `+1 (${maskedNumber.slice(0, 3)}) ${maskedNumber.slice(3, 6)}-${maskedNumber.slice(6, 10)}`
        return maskedNumber
      },

      close () {
        this.$emit('on-close')
      },

      parseCellNumberFromMaskFormat (cellNumber) {
        return `+${cellNumber.replace(/[^0-9,]+/g, '')}`
      },

      isFormValid () {
        this.notification = ''
        if (!this.field.name || !this.field.name.length) {
          this.notification = 'Fill all the required fields'
          return false
        }
        if (!this.field.number || !this.field.number.length || this.parseCellNumberFromMaskFormat(this.field.number).length !== 12) {
          this.notification = 'Provide a valid 10 digit cell number'
          return false
        }
        return true
      },

      submit () {
        this.isFormDirty = true
        if (!this.isFormValid()) {
          this.snackbar = true
          return
        }
        if (this.isEditMode) {
          // Update
          const payload = {
            SenderID: 0, // only for update
            SendNumber: this.parseCellNumberFromMaskFormat(this.field.number),
            SenderName: this.field.name,
            Username: this.username,
          }
          if (this.field.signature && this.field.signature.length) {
            payload.Signiture = this.field.signature
          }
          this.$emit('on-submit')
          this.httpConnector.makeRequest('post', `${this.apiEndpoint}senderpn?code=${this.apiCode}`, payload)
            .then((res) => {
              this.$emit('on-success', res)
              this.close()
            })
            .catch((error) => {
              this.$emit('on-error', error)
            })
        } else {
          // Add
          const payload = {
            SendNumber: this.field.number,
            SenderName: this.field.name,
            Username: this.username,
          }
          if (this.field.signature && this.field.signature.length) {
            payload.Signiture = this.field.signature
          }
          this.$emit('on-submit')
          this.httpConnector.makeRequest('post', `${this.apiEndpoint}senderpn?code=${this.apiCode}`, payload)
            .then((res) => {
              this.$emit('on-success', res)
              this.close()
            })
            .catch((error) => {
              this.$emit('on-error', error)
            })
        }
      },
    },
  }
</script>
